import { BiHome } from "react-icons/bi";
import { ReactComponent as Bookmark } from "./Assets/Bookmark.svg";

export const navigationLinks = [
   {
      id: "1",
      pathName: "/",
      name: "home",
      Icon: BiHome,
   },
   {
      id: "2",
      pathName: "/Bookmarks_History",
      name: "Bookmarks, History",
      Icon: Bookmark,
   },
];

export const reciters = [
   {
      name: "AbdulBaset AbdulSamad",
      id: 1,
      specificity: "Mujawwad",
   },
   {
      name: "AbdulBaset AbdulSamad",
      id: 2,
   },
   {
      name: "Abdur-Rahman as-Sudais",
      id: 3,
   },
   {
      name: "Abu Bakr al-Shatri",
      id: 4,
   },
   {
      name: "Hani ar-RifaiAbu Bakr al-Shatri",
      id: 5,
   },
   {
      name: "Mahmoud Khalil Al-Husary",
      id: 6,
   },
   {
      name: "Mishari Rashid al-`Afasy",
      id: 7,
   },
   {
      name: "Mohamed Siddiq al-Minshawi",
      id: 9,
   },
   {
      name: "Sa`ud ash-Shuraym",
      id: 10,
   },
   {
      name: "Mohamed al-Tablawi",
      id: 11,
   },
   {
      name: "Mahmoud Khalil Al-Husary",
      id: 12,
      specificity: "Muallim",
   },
];

export const surahsOverviewData = [
   {
      number: 1,
      name: "ٱلْفَاتِحَةِ",
      englishName: "Al-Faatiha",
      englishNameTranslation: "The Opening",
      numberOfAyahs: 7,
   },
   {
      number: 2,
      name: "البَقَرَةِ",
      englishName: "Al-Baqara",
      englishNameTranslation: "The Cow",
      numberOfAyahs: 286,
   },
   {
      number: 3,
      name: "آلِ عِمۡرَانَ",
      englishName: "Aal-i-Imraan",
      englishNameTranslation: "The Family of Imraan",
      numberOfAyahs: 200,
   },
   {
      number: 4,
      name: "النِّسَاءِ",
      englishName: "An-Nisaa",
      englishNameTranslation: "The Women",
      numberOfAyahs: 176,
   },
   {
      number: 5,
      name: "المَائـِدَةِ",
      englishName: "Al-Maaida",
      englishNameTranslation: "The Table",
      numberOfAyahs: 120,
   },
   {
      number: 6,
      name: "الأَنۡعَامِ",
      englishName: "Al-An'aam",
      englishNameTranslation: "The Cattle",
      numberOfAyahs: 165,
   },
   {
      number: 7,
      name: "الأَعۡرَافِ",
      englishName: "Al-A'raaf",
      englishNameTranslation: "The Heights",
      numberOfAyahs: 206,
   },
   {
      number: 8,
      name: "الأَنفَالِ",
      englishName: "Al-Anfaal",
      englishNameTranslation: "The Spoils of War",
      numberOfAyahs: 75,
   },
   {
      number: 9,
      name: "التَّوۡبَةِ",
      englishName: "At-Tawba",
      englishNameTranslation: "The Repentance",
      numberOfAyahs: 129,
   },
   {
      number: 10,
      name: "يُونُسَ",
      englishName: "Yunus",
      englishNameTranslation: "Jonas",
      numberOfAyahs: 109,
   },
   {
      number: 11,
      name: "هُودٍ",
      englishName: "Hud",
      englishNameTranslation: "Hud",
      numberOfAyahs: 123,
   },
   {
      number: 12,
      name: "يُوسُفَ",
      englishName: "Yusuf",
      englishNameTranslation: "Joseph",
      numberOfAyahs: 111,
   },
   {
      number: 13,
      name: "الرَّعۡدِ",
      englishName: "Ar-Ra'd",
      englishNameTranslation: "The Thunder",
      numberOfAyahs: 43,
   },
   {
      number: 14,
      name: "إِبۡرَاهِيمَ",
      englishName: "Ibrahim",
      englishNameTranslation: "Abraham",
      numberOfAyahs: 52,
   },
   {
      number: 15,
      name: "الحِجۡرِ",
      englishName: "Al-Hijr",
      englishNameTranslation: "The Rock",
      numberOfAyahs: 99,
   },
   {
      number: 16,
      name: "النَّحۡلِ",
      englishName: "An-Nahl",
      englishNameTranslation: "The Bee",
      numberOfAyahs: 128,
   },
   {
      number: 17,
      name: "الإِسۡرَاءِ",
      englishName: "Al-Israa",
      englishNameTranslation: "The Night Journey",
      numberOfAyahs: 111,
   },
   {
      number: 18,
      name: "الكَهۡفِ",
      englishName: "Al-Kahf",
      englishNameTranslation: "The Cave",
      numberOfAyahs: 110,
   },
   {
      number: 19,
      name: "مَرۡيَمَ",
      englishName: "Maryam",
      englishNameTranslation: "Mary",
      numberOfAyahs: 98,
   },
   {
      number: 20,
      name: "طه",
      englishName: "Taa-Haa",
      englishNameTranslation: "Taa-Haa",
      numberOfAyahs: 135,
   },
   {
      number: 21,
      name: "الأَنبِيَاءِ",
      englishName: "Al-Anbiyaa",
      englishNameTranslation: "The Prophets",
      numberOfAyahs: 112,
   },
   {
      number: 22,
      name: "الحَجِّ",
      englishName: "Al-Hajj",
      englishNameTranslation: "The Pilgrimage",
      numberOfAyahs: 78,
   },
   {
      number: 23,
      name: "المُؤۡمِنُونَ",
      englishName: "Al-Muminoon",
      englishNameTranslation: "The Believers",
      numberOfAyahs: 118,
   },
   {
      number: 24,
      name: "النُّورِ",
      englishName: "An-Noor",
      englishNameTranslation: "The Light",
      numberOfAyahs: 64,
   },
   {
      number: 25,
      name: "الفُرۡقَانِ",
      englishName: "Al-Furqaan",
      englishNameTranslation: "The Criterion",
      numberOfAyahs: 77,
   },
   {
      number: 26,
      name: "الشُّعَرَاءِ",
      englishName: "Ash-Shu'araa",
      englishNameTranslation: "The Poets",
      numberOfAyahs: 227,
   },
   {
      number: 27,
      name: "النَّمۡلِ",
      englishName: "An-Naml",
      englishNameTranslation: "The Ant",
      numberOfAyahs: 93,
   },
   {
      number: 28,
      name: "القَصَصِ",
      englishName: "Al-Qasas",
      englishNameTranslation: "The Stories",
      numberOfAyahs: 88,
   },
   {
      number: 29,
      name: "العَنكَبُوتِ",
      englishName: "Al-Ankaboot",
      englishNameTranslation: "The Spider",
      numberOfAyahs: 69,
   },
   {
      number: 30,
      name: "الرُّومِ",
      englishName: "Ar-Room",
      englishNameTranslation: "The Romans",
      numberOfAyahs: 60,
   },
   {
      number: 31,
      name: "لُقۡمَانَ",
      englishName: "Luqman",
      englishNameTranslation: "Luqman",
      numberOfAyahs: 34,
   },
   {
      number: 32,
      name: "السَّجۡدَةِ",
      englishName: "As-Sajda",
      englishNameTranslation: "The Prostration",
      numberOfAyahs: 30,
   },
   {
      number: 33,
      name: "الأَحۡزَابِ",
      englishName: "Al-Ahzaab",
      englishNameTranslation: "The Clans",
      numberOfAyahs: 73,
   },
   {
      number: 34,
      name: "سَبَإٍ",
      englishName: "Saba",
      englishNameTranslation: "Sheba",
      numberOfAyahs: 54,
   },
   {
      number: 35,
      name: "فَاطِرٍ",
      englishName: "Faatir",
      englishNameTranslation: "The Originator",
      numberOfAyahs: 45,
   },
   {
      number: 36,
      name: "يسٓ",
      englishName: "Yaseen",
      englishNameTranslation: "Yaseen",
      numberOfAyahs: 83,
   },
   {
      number: 37,
      name: "الصَّافَّاتِ",
      englishName: "As-Saaffaat",
      englishNameTranslation: "Those drawn up in Ranks",
      numberOfAyahs: 182,
   },
   {
      number: 38,
      name: "صٓ",
      englishName: "Saad",
      englishNameTranslation: "The letter Saad",
      numberOfAyahs: 88,
   },
   {
      number: 39,
      name: "الزُّمَرِ",
      englishName: "Az-Zumar",
      englishNameTranslation: "The Groups",
      numberOfAyahs: 75,
   },
   {
      number: 40,
      name: "غَافِرٍ",
      englishName: "Ghafir",
      englishNameTranslation: "The Forgiver",
      numberOfAyahs: 85,
   },
   {
      number: 41,
      name: "فُصِّلَتۡ",
      englishName: "Fussilat",
      englishNameTranslation: "Explained in detail",
      numberOfAyahs: 54,
   },
   {
      number: 42,
      name: "الشُّورَىٰ",
      englishName: "Ash-Shura",
      englishNameTranslation: "Consultation",
      numberOfAyahs: 53,
   },
   {
      number: 43,
      name: "الزُّخۡرُفِ",
      englishName: "Az-Zukhruf",
      englishNameTranslation: "Ornaments of gold",
      numberOfAyahs: 89,
   },
   {
      number: 44,
      name: "الدُّخَانِ",
      englishName: "Ad-Dukhaan",
      englishNameTranslation: "The Smoke",
      numberOfAyahs: 59,
   },
   {
      number: 45,
      name: "الجَاثِيَةِ",
      englishName: "Al-Jaathiya",
      englishNameTranslation: "Crouching",
      numberOfAyahs: 37,
   },
   {
      number: 46,
      name: "الأَحۡقَافِ",
      englishName: "Al-Ahqaf",
      englishNameTranslation: "The Dunes",
      numberOfAyahs: 35,
   },
   {
      number: 47,
      name: "مُحَمَّدٍ",
      englishName: "Muhammad",
      englishNameTranslation: "Muhammad",
      numberOfAyahs: 38,
   },
   {
      number: 48,
      name: "الفَتۡحِ",
      englishName: "Al-Fath",
      englishNameTranslation: "The Victory",
      numberOfAyahs: 29,
   },
   {
      number: 49,
      name: "الحُجُرَاتِ",
      englishName: "Al-Hujuraat",
      englishNameTranslation: "The Inner Apartments",
      numberOfAyahs: 18,
   },
   {
      number: 50,
      name: "قٓ",
      englishName: "Qaaf",
      englishNameTranslation: "The letter Qaaf",
      numberOfAyahs: 45,
   },
   {
      number: 51,
      name: "الذَّارِيَاتِ",
      englishName: "Adh-Dhaariyat",
      englishNameTranslation: "The Winnowing Winds",
      numberOfAyahs: 60,
   },
   {
      number: 52,
      name: "الطُّورِ",
      englishName: "At-Tur",
      englishNameTranslation: "The Mount",
      numberOfAyahs: 49,
   },
   {
      number: 53,
      name: "النَّجۡمِ",
      englishName: "An-Najm",
      englishNameTranslation: "The Star",
      numberOfAyahs: 62,
   },
   {
      number: 54,
      name: "القَمَرِ",
      englishName: "Al-Qamar",
      englishNameTranslation: "The Moon",
      numberOfAyahs: 55,
   },
   {
      number: 55,
      name: "الرَّحۡمَٰن",
      englishName: "Ar-Rahmaan",
      englishNameTranslation: "The Beneficent",
      numberOfAyahs: 78,
   },
   {
      number: 56,
      name: "الوَاقِعَةِ",
      englishName: "Al-Waaqia",
      englishNameTranslation: "The Inevitable",
      numberOfAyahs: 96,
   },
   {
      number: 57,
      name: "الحَدِيدِ",
      englishName: "Al-Hadid",
      englishNameTranslation: "The Iron",
      numberOfAyahs: 29,
   },
   {
      number: 58,
      name: "المُجَادلَةِ",
      englishName: "Al-Mujaadila",
      englishNameTranslation: "The Pleading Woman",
      numberOfAyahs: 22,
   },
   {
      number: 59,
      name: "الحَشۡرِ",
      englishName: "Al-Hashr",
      englishNameTranslation: "The Exile",
      numberOfAyahs: 24,
   },
   {
      number: 60,
      name: "المُمۡتَحنَةِ",
      englishName: "Al-Mumtahana",
      englishNameTranslation: "She that is to be examined",
      numberOfAyahs: 13,
   },
   {
      number: 61,
      name: "الصَّفِّ",
      englishName: "As-Saff",
      englishNameTranslation: "The Ranks",
      numberOfAyahs: 14,
   },
   {
      number: 62,
      name: "الجُمُعَةِ",
      englishName: "Al-Jumu'a",
      englishNameTranslation: "Friday",
      numberOfAyahs: 11,
   },
   {
      number: 63,
      name: "المُنَافِقُونَ",
      englishName: "Al-Munaafiqoon",
      englishNameTranslation: "The Hypocrites",
      numberOfAyahs: 11,
   },
   {
      number: 64,
      name: "التَّغَابُنِ",
      englishName: "At-Taghaabun",
      englishNameTranslation: "Mutual Disillusion",
      numberOfAyahs: 18,
   },
   {
      number: 65,
      name: "الطَّلَاقِ",
      englishName: "At-Talaaq",
      englishNameTranslation: "Divorce",
      numberOfAyahs: 12,
   },
   {
      number: 66,
      name: "التَّحۡرِيمِ",
      englishName: "At-Tahrim",
      englishNameTranslation: "The Prohibition",
      numberOfAyahs: 12,
   },
   {
      number: 67,
      name: "المُلۡكِ",
      englishName: "Al-Mulk",
      englishNameTranslation: "The Sovereignty",
      numberOfAyahs: 30,
   },
   {
      number: 68,
      name: "القَلَمِ",
      englishName: "Al-Qalam",
      englishNameTranslation: "The Pen",
      numberOfAyahs: 52,
   },
   {
      number: 69,
      name: "الحَاقَّةِ",
      englishName: "Al-Haaqqa",
      englishNameTranslation: "The Reality",
      numberOfAyahs: 52,
   },
   {
      number: 70,
      name: "المَعَارِجِ",
      englishName: "Al-Ma'aarij",
      englishNameTranslation: "The Ascending Stairways",
      numberOfAyahs: 44,
   },
   {
      number: 71,
      name: "نُوحٍ",
      englishName: "Nooh",
      englishNameTranslation: "Noah",
      numberOfAyahs: 28,
   },
   {
      number: 72,
      name: "الجِنِّ",
      englishName: "Al-Jinn",
      englishNameTranslation: "The Jinn",
      numberOfAyahs: 28,
   },
   {
      number: 73,
      name: "المُزَّمِّلِ",
      englishName: "Al-Muzzammil",
      englishNameTranslation: "The Enshrouded One",
      numberOfAyahs: 20,
   },
   {
      number: 74,
      name: "المُدَّثِّرِ",
      englishName: "Al-Muddaththir",
      englishNameTranslation: "The Cloaked One",
      numberOfAyahs: 56,
   },
   {
      number: 75,
      name: "القِيَامَةِ",
      englishName: "Al-Qiyaama",
      englishNameTranslation: "The Resurrection",
      numberOfAyahs: 40,
   },
   {
      number: 76,
      name: "الإِنسَانِ",
      englishName: "Al-Insaan",
      englishNameTranslation: "Man",
      numberOfAyahs: 31,
   },
   {
      number: 77,
      name: "المُرۡسَلَاتِ",
      englishName: "Al-Mursalaat",
      englishNameTranslation: "The Emissaries",
      numberOfAyahs: 50,
   },
   {
      number: 78,
      name: "النَّبَإِ",
      englishName: "An-Naba",
      englishNameTranslation: "The Announcement",
      numberOfAyahs: 40,
   },
   {
      number: 79,
      name: "النَّازِعَاتِ",
      englishName: "An-Naazi'aat",
      englishNameTranslation: "Those who drag forth",
      numberOfAyahs: 46,
   },
   {
      number: 80,
      name: "عَبَسَ",
      englishName: "Abasa",
      englishNameTranslation: "He frowned",
      numberOfAyahs: 42,
   },
   {
      number: 81,
      name: "التَّكۡوِيرِ",
      englishName: "At-Takwir",
      englishNameTranslation: "The Overthrowing",
      numberOfAyahs: 29,
   },
   {
      number: 82,
      name: "الانفِطَارِ",
      englishName: "Al-Infitaar",
      englishNameTranslation: "The Cleaving",
      numberOfAyahs: 19,
   },
   {
      number: 83,
      name: "المُطَفِّفِينَ",
      englishName: "Al-Mutaffifin",
      englishNameTranslation: "Defrauding",
      numberOfAyahs: 36,
   },
   {
      number: 84,
      name: "الانشِقَاقِ",
      englishName: "Al-Inshiqaaq",
      englishNameTranslation: "The Splitting Open",
      numberOfAyahs: 25,
   },
   {
      number: 85,
      name: "البُرُوجِ",
      englishName: "Al-Burooj",
      englishNameTranslation: "The Constellations",
      numberOfAyahs: 22,
   },
   {
      number: 86,
      name: "الطَّارِقِ",
      englishName: "At-Taariq",
      englishNameTranslation: "The Morning Star",
      numberOfAyahs: 17,
   },
   {
      number: 87,
      name: "الأَعۡلَىٰ",
      englishName: "Al-A'laa",
      englishNameTranslation: "The Most High",
      numberOfAyahs: 19,
   },
   {
      number: 88,
      name: "الغَاشِيَةِ",
      englishName: "Al-Ghaashiya",
      englishNameTranslation: "The Overwhelming",
      numberOfAyahs: 26,
   },
   {
      number: 89,
      name: "الفَجۡرِ",
      englishName: "Al-Fajr",
      englishNameTranslation: "The Dawn",
      numberOfAyahs: 30,
   },
   {
      number: 90,
      name: "البَلَدِ",
      englishName: "Al-Balad",
      englishNameTranslation: "The City",
      numberOfAyahs: 20,
   },
   {
      number: 91,
      name: "الشَّمۡسِ",
      englishName: "Ash-Shams",
      englishNameTranslation: "The Sun",
      numberOfAyahs: 15,
   },
   {
      number: 92,
      name: "اللَّيۡلِ",
      englishName: "Al-Lail",
      englishNameTranslation: "The Night",
      numberOfAyahs: 21,
   },
   {
      number: 93,
      name: "الضُّحَىٰ",
      englishName: "Ad-Dhuhaa",
      englishNameTranslation: "The Morning Hours",
      numberOfAyahs: 11,
   },
   {
      number: 94,
      name: "الشَّرۡحِ",
      englishName: "Ash-Sharh",
      englishNameTranslation: "The Consolation",
      numberOfAyahs: 8,
   },
   {
      number: 95,
      name: "التِّينِ",
      englishName: "At-Tin",
      englishNameTranslation: "The Fig",
      numberOfAyahs: 8,
   },
   {
      number: 96,
      name: "العَلَقِ",
      englishName: "Al-Alaq",
      englishNameTranslation: "The Clot",
      numberOfAyahs: 19,
   },
   {
      number: 97,
      name: "القَدۡرِ",
      englishName: "Al-Qadr",
      englishNameTranslation: "The Power, Fate",
      numberOfAyahs: 5,
   },
   {
      number: 98,
      name: "البَيِّنَةِ",
      englishName: "Al-Bayyina",
      englishNameTranslation: "The Evidence",
      numberOfAyahs: 8,
   },
   {
      number: 99,
      name: "الزَّلۡزَلَةِ",
      englishName: "Az-Zalzala",
      englishNameTranslation: "The Earthquake",
      numberOfAyahs: 8,
   },
   {
      number: 100,
      name: "العَادِيَاتِ",
      englishName: "Al-Aadiyaat",
      englishNameTranslation: "The Chargers",
      numberOfAyahs: 11,
   },
   {
      number: 101,
      name: "القَارِعَةِ",
      englishName: "Al-Qaari'a",
      englishNameTranslation: "The Calamity",
      numberOfAyahs: 11,
   },
   {
      number: 102,
      name: "التَّكَاثُرِ",
      englishName: "At-Takaathur",
      englishNameTranslation: "Competition",
      numberOfAyahs: 8,
   },
   {
      number: 103,
      name: "العَصۡرِ",
      englishName: "Al-Asr",
      englishNameTranslation: "The Declining Day, Epoch",
      numberOfAyahs: 3,
   },
   {
      number: 104,
      name: "الهُمَزَةِ",
      englishName: "Al-Humaza",
      englishNameTranslation: "The Traducer",
      numberOfAyahs: 9,
   },
   {
      number: 105,
      name: "الفِيلِ",
      englishName: "Al-Fil",
      englishNameTranslation: "The Elephant",
      numberOfAyahs: 5,
   },
   {
      number: 106,
      name: "قُرَيۡشٍ",
      englishName: "Quraish",
      englishNameTranslation: "Quraysh",
      numberOfAyahs: 4,
   },
   {
      number: 107,
      name: "المَاعُونِ",
      englishName: "Al-Maa'un",
      englishNameTranslation: "Almsgiving",
      numberOfAyahs: 7,
   },
   {
      number: 108,
      name: "الكَوۡثَرِ",
      englishName: "Al-Kawthar",
      englishNameTranslation: "Abundance",
      numberOfAyahs: 3,
   },
   {
      number: 109,
      name: "الكَافِرُونَ",
      englishName: "Al-Kaafiroon",
      englishNameTranslation: "The Disbelievers",
      numberOfAyahs: 6,
   },
   {
      number: 110,
      name: "النَّصۡرِ",
      englishName: "An-Nasr",
      englishNameTranslation: "Divine Support",
      numberOfAyahs: 3,
   },
   {
      number: 111,
      name: "المَسَدِ",
      englishName: "Al-Masad",
      englishNameTranslation: "The Palm Fibre",
      numberOfAyahs: 5,
   },
   {
      number: 112,
      name: "الإِخۡلَاصِ",
      englishName: "Al-Ikhlaas",
      englishNameTranslation: "Sincerity",
      numberOfAyahs: 4,
   },
   {
      number: 113,
      name: "الفَلَقِ",
      englishName: "Al-Falaq",
      englishNameTranslation: "The Dawn",
      numberOfAyahs: 5,
   },
   {
      number: 114,
      name: "النَّاسِ",
      englishName: "An-Naas",
      englishNameTranslation: "Mankind",
      numberOfAyahs: 6,
   },
];

export const numberOfSurahsOnQuran = 114;

export const availableTranslationsApi = `https://api.quran.com/api/v4/resources/translations?language=eg`;
export const versesApi = `https://api.quran.com/api/v4/verses/by_chapter/$\{surah_id}?language=en&words=true&translations=$\{translationSrc_id}&fields=text_imlaei&word_fields=v1_page,code_v1&page=$\{page_number}&per_page=10`;
export const verseApi = `https://api.quran.com/api/v4/verses/by_key/$\{verseKey}?language=en&words=true&translations=$\{translationSrc_id}&word_fields=v1_page%2Ccode_v1&fields=text_imlaei`;
export const translationsApi = `https://api.quran.com/api/v4/quran/translations/$\{translationSrc_id}?fields=verse_key&chapter_number=$\{surah_id}`;
export const singleTranslationApi = `https://api.quran.com/api/v4/quran/translations/$\{translationSrc_id}?verse_key=$\{VerseKey}`;
export const audioApi = `https://api.qurancdn.com/api/qdc/audio/reciters/$\{reciter_id}/audio_files?chapter=$\{surah_id}&segments=true`;
